.ForecastformMbl .carousel-item .headertitle {
  position: absolute;
  font-size: 14px;
  color: #fff;
  right: 0px;
  padding: 10px;
  font-family: "Neo-Sans", sans-serif;
}

.ForecastformMbl .carousel-indicators li {
  width: 10px !important;
  height: 10px !important;
  border-radius: 10px !important;
  /*border-bottom-ra;*/
  border-bottom: none;
  border-top: none;
}
.ForecastformMbl .form-Column .Forecastformtitle {
  padding: 0px 20px;
  font-size: 20px;
  font-weight: bold;
  font-family: "Neo-Sans", sans-serif;
}
.ForecastformMbl .form-Column .form-group {
  padding: 0px 20px;
}
.ForecastformMbl .carousel-item .colorVisionLogo {
  position: absolute;
  padding: 10px;
}
.ForecastformMbl .form-Column {
  overflow-y: scroll;
  padding-bottom: 65px;
  padding-top: 20px;
}
.ForecastformMbl .form-Column .forecastContactformSubmitBtn {
  position: fixed;
  padding: 13px;
  bottom: 45px;
  margin: 0px !important;
  border-radius: 0px 0px 4px 4px !important;
  text-transform: uppercase;
  background-color: #fa2129;
  border: 2px solid #fa2129b8;
  font-size: 14px;
  font-weight: bold;
  z-index: 999;
}
.ForecastformMbl .form-Column .forecastContactformSubmitbuynowBtn {
  position: fixed;
  padding: 13px;
  bottom: 0px;
  margin: 0px !important;
  border-radius: 0px 0px 4px 4px !important;
  text-transform: uppercase;
  background-color: #fa2129;
  border: 2px solid #fa2129b8;
  font-size: 14px;
  font-weight: bold;
  z-index: 999;
}
.ForecastformMbl .form-Column .forecastContactformSubmitBtn:focus {
  outline: none !important;
  box-shadow: none !important;
}
.ForecastformMbl .form-Column .label-name {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}
.ForecastformMbl .form-Column .btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #fa2129;
  border-color: #fa2129;
  outline: none !important;
  box-shadow: none !important;
}
.ForecastformMbl .form-Column ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #000000 !important;
  opacity: 0.35 !important;
  font-weight: bold !important;
  font-size: 14px !important;
}
.ForecastformMbl .form-Column ::placeholder {
  /* Microsoft Edge */
  color: #000000 !important;
  opacity: 0.35 !important;
  font-weight: bold !important;
  font-size: 14px !important;
}
.ForecastformMbl .form-Column :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000000 !important;
  opacity: 0.35 !important;
  font-weight: bold !important;
  font-size: 14px !important;
}
