body {
  overflow-x: hidden;
}
/*.background1{*/
/*    position: relative;*/
/*    top: -84px;*/
/*    left: -177.516357421875px;*/
/*    width: 502px;*/
/*    height: 412px;*/
/*    background: transparent url('./../../Assets/Images/blob-shape1.svg') 0% 0% no-repeat padding-box;*/
/*    opacity: 1;*/
/*}*/
/*.background2{*/
/*    top: 409px;*/
/*    left: -557.663818359375px;*/
/*    width: 1261px;*/
/*    height: 1328px;*/
/*    transform: matrix(-0.83, -0.56, 0.56, -0.83, 0, 0);*/
/*    background: transparent url('./../../Assets/Images/blob-shape2.svg') 0% 0% no-repeat padding-box;*/
/*    opacity: 1;*/
/*}*/
.colorTheme p {
  font-family: "Lato", sans-serif !important;
  font-weight: 600;
}
.themeImage p {
  text-align: center;
}
.themeImage img {
  width: 65%;
}
.exploreBtn {
  border-radius: 40px !important;
  border: 1px solid #c2af52 !important;
  background-color: #fff5c4 !important;
  color: #3c336d !important;
  padding: 7px 50px !important;
  font-family: "Lato", sans-serif !important;
  font-weight: bold !important;
  font-size: 12px !important;
  letter-spacing: 0.96px !important;
  box-shadow: 0px 3px 6px #00000029;
}
.exploreBtn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(206, 189, 106, 0.71) !important;
}
/* .leftCol{*/
/*     display: flex;*/
/*     justify-content: center;*/
/*     align-items: center;*/
/* }*/
/*.Col_1{*/
/*    text-align: center;*/
/*}*/
.AboutTheme {
  font-family: "Lato", sans-serif;
  font-weight: 600;
  text-align: justify;
  font-size: 18px;
}
.paletteTitle,
.exploreTitle {
  font-size: 24px;
  font-family: "Neo-Sans", sans-serif !important;
  font-weight: bold;
}

.paletteSubTitle {
  font-weight: 500;
  opacity: 1;
  font-family: "lato", sans-serif;
}
.overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: #000;
  opacity: 0.7;
}

.region_title {
  font-family: "Neo-Sans", sans-serif !important;
  font-weight: 600;
  font-size: 20px;
  color: rgba(255, 255, 255, 1);
}

.on {
  stroke: red;
  stroke-width: 2;
}

.carousel-caption {
  top: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 1150px) {
  .dynamic_colour_vision_component .dynamic_colour_vision_color_palate {
    overflow: scroll;
    justify-content: left;
  }
}
.dynamic_colour_vision_component input[type="radio"] {
  display: none;
}

.dynamic_colour_vision_component .dynamic_colour_vision_color_palate .box {
  min-width: 10.5em;
  height: 7em;
  cursor: pointer;
}
.dynamic_colour_vision_component .dynamic_colour_vision_color_palate .box span {
  transition: all 300ms ease;
  user-select: none;
  color: #000;
}

.dynamic_colour_vision_color_palate .active_color {
  border: 2px solid #f1510b75;
}
.dynamic_colour_vision_component .desktop_download_btn {
  color: #3c336d;
  border: none;
  padding: 16px 32px;
  font-size: 14px;
  font-weight: 700;
  background: #fff5c4 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 28px;
}
.dynamic_colour_vision_component .mobile_download_btn {
  color: #3c336d;
  border: none;
  font-size: 12px;
  font-weight: 700;
  padding: 10px 25px;
  background: #fff5c4 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 28px;
}
.desktop_download_btn:focus,
.mobile_download_btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.dynamic_colour_vision_component {
  height: 100%;
  position: relative;
}
.dynamic_colour_vision_component .content_section_title {
  font-size: 24px;
  font-family: "Neo-Sans", sans-serif !important;
  font-weight: bold;
}
.dynamic_colour_vision_component .content_section_para {
  font-size: 14px;
  width: 85%;
  font-family: "Lato", sans-serif !important;
  font-weight: normal;
}
.color_content {
  position: absolute;
  bottom: -8px;
  left: 0;
  right: 0;
}
.description_part {
  color: #ffffff;
}

@media screen and (max-width: 540px) {
  .description_part {
    color: #000000;
  }
  .color_content {
    position: relative;
  }
  .desktop_download_btn {
    display: none !important;
  }
  .content_section_para {
    width: 100%;
  }
  .dynamic_colour_vision_color_palate {
    display: none !important;
  }
  .box {
    /*width: 10rem;*/
    height: 7rem;
  }
  .mobile_download_btn {
    display: block !important;
  }
  .dynamic_colour_vision_component .mobile_carousel {
    display: block !important;
  }
}

.mobile_download_btn {
  display: none;
}
.dynamic_colour_vision_component .mobile_carousel {
  display: none;
}

/*for responsive typography*/

/*.dynamic_colour_vision_component  .color_title{*/
/*  font-size: 18px;*/
/*}*/
@media screen and (max-width: 544px) {
  .dynamic_colour_vision_component .color_title {
    font-size: 14px;
    font-family: "Lato", sans-serif;
    font-weight: bold;
  }
  .dynamic_colour_vision_component .color_code {
    font-size: 12px;
    font-family: "Lato", sans-serif;
  }
}

@media screen and (min-width: 545px) and (max-width: 991px) {
  .dynamic_colour_vision_component .color_title {
    font-size: 14px;
    font-family: "Lato", sans-serif;
    font-weight: bold;
  }
  .dynamic_colour_vision_component .color_code {
    font-size: 12px;
    font-family: "Lato", sans-serif;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .dynamic_colour_vision_component .color_title {
    font-size: 18px;
    font-family: "Lato", sans-serif;
    font-weight: bold;
  }
  .dynamic_colour_vision_component .color_code {
    font-size: 14px;
    font-family: "Lato", sans-serif;
  }
}
@media screen and (min-width: 1200px) {
  .dynamic_colour_vision_component .color_title {
    font-size: 18px;
    font-family: "Lato", sans-serif;
    font-weight: bold;
  }
  .dynamic_colour_vision_component .color_code {
    font-size: 14px;
    font-family: "Lato", sans-serif;
  }
}

.dynamic_colour_vision_component .carousel-indicators {
  bottom: -50px !important;
  display: flex !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  justify-content: flex-end !important;
  z-index: -1 !important;
}
.dynamic_colour_vision_component .carousel-indicators li {
  background-color: gray !important;
}
.dynamic_colour_vision_component .carousel-indicators li {
  width: 10px !important;
  height: 10px !important;
  border-radius: 100% !important;
}

.mobile_palate {
  display: none;
}

/*.paletteHeadTitle{*/
/*    display: none;*/
/*}*/
/*.paletteHeadSubTitle{*/
/*    display: none;*/
/*}*/

@media screen and (max-width: 700px) {
  .ThemeHead {
    flex-direction: column-reverse !important;
  }
  .mobile_palate {
    display: block;
  }
  .paletteHeadTitle {
    /*display: block;*/
    font-size: 24px;
    font-family: "Neo-Sans", sans-serif !important;
    font-weight: bold;
    text-align: center;
  }
  .paletteHeadSubTitle {
    /*display: block;*/
    font-size: 16px;
    font-family: "lato", sans-serif !important;
    font-weight: normal;
    text-align: center;
  }
  .paletteTitle {
    display: none;
  }
  .color_palate_image,
  .color_palate_image_end hr {
    width: 100% !important;
  }
}
.color_palate_image,
.color_palate_image_end hr {
  width: 60%;
}
.active_palete_image {
  filter: drop-shadow(0px 10px 5px gray);
  -webkit-filter: drop-shadow(0px 10px 5px gray);
}
.ThemeHead input[type="radio"] {
  display: none;
}

.dynamic_colour_vision_color_palate {
  overflow-x: scroll;
  white-space: nowrap;
}

.dynamic_colour_vision_color_palate::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.dynamic_colour_vision_color_palate::-webkit-scrollbar {
  width: 0px;
  height: 12px;
  background-color: #f5f5f5;
}

.dynamic_colour_vision_color_palate::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}
