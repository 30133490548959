.landingpage_Contactpage{
    background-image: url("../../Assets/Images/landingpage2020bg.png");
    background-size: 100%;
    background-position: center;
    height: 100vh;
    background-repeat: no-repeat;
}
.landingpage_Contactpage .Conatactform-Container{
    padding: 0px 45px;
}
.landingpage_Contactpage .Conatactform-Container .contact-header{
    display: flex;
    justify-content: space-between;
    align-items: center;


}
.landingpage_Contactpage .Conatactform-Container .contact-header .contact_Logo{
    padding: 20px 0px 0px 0px ;
    width: 8%;
    cursor: pointer;
}
.landingpage_Contactpage .Conatactform-Container .contact-header .header_text{
    color: #FFFFFF;
    opacity: 1;
    font-family: Neo-Sans, serif;
    font-weight: 300;
    font-size: 20px;
}
.landingpage_Contactpage .Conatactform-Container .form-Column{
    width: 28vw;
    background-color: #fff;
    border-radius: 4px;
    padding: 10px 0px 0px 0px;
    margin-top: 50px;
}
.landingpage_Contactpage .Conatactform-Container .form-Column form .forecastContactformSubmitBtn{
    margin: 0px !important;
    border-radius: 0px 0px 4px 4px !important;
    text-transform: uppercase;
    background-color: #FA2129;
    padding: 10px;
    border: 2px solid #fa2129b8;
    position: relative;


}
.landingpage_Contactpage .Conatactform-Container .form-Column form button:focus{
    outline: none;
    box-shadow: none;
}
.landingpage_Contactpage .Conatactform-Container .form-Column .form-group{
    padding: 0px 20px;
}
.landingpage_Contactpage .Conatactform-Container .form-Column .Forecastformtitle{
    padding: 0px 20px;
    font-family: Neo-Sans, sans-serif;
    font-weight: bold;
    font-size: 25px;

}
.landingpage_Contactpage .Conatactform-Container .form-Column ::-ms-input-placeholder { /* Microsoft Edge */
    color: #000000 !important;
    opacity: 0.35 !important;
    font-weight: bold !important;
    font-size: 14px !important;

}
.landingpage_Contactpage .Conatactform-Container .form-Column ::placeholder { /* Microsoft Edge */
    color: #000000 !important;
    opacity: 0.35 !important;
    font-weight: bold !important;
    font-size: 14px !important;
}
.landingpage_Contactpage .Conatactform-Container .form-Column :-ms-input-placeholder {/* Internet Explorer 10-11 */
    color: #000000 !important;
    opacity: 0.35 !important;
    font-weight: bold !important;
    font-size: 14px !important;
}
.landingpage_Contactpage .Conatactform-Container .form-Column .form-control:focus{
    outline: none;
    box-shadow: none;

}
.landingpage_Contactpage .Conatactform-Container .form-Column  .label-name{

    text-transform: uppercase;
    font-family: 'Lato', sans-serif;
    color: #2D2D2D;
    letter-spacing: 1px;

}

@media screen and (max-width:1500px) {
    .landingpage_Contactpage{ background-image: url("../../Assets/Images/landingpage2020bg.png");
        background-size: 100% 100% !important;
        background-position: center;
        height: 100vh;
        background-repeat: no-repeat;
    }
    .landingpage_Contactpage .Conatactform-Container .form-Column{
        width: 40vw !important;
        /*margin-bottom: 0px !important;*/
    }
    .landingpage_Contactpage .Conatactform-Container .contact-header .contact_Logo{
        width: 8% !important;
        cursor: pointer;
    }
    .landingpage_Contactpage .Conatactform-Container .form-Column {
        margin-top: 14px !important;
    }
    .landingpage_Contactpage .Conatactform-Container .form-Column .Forecastformtitle {
        font-size: 18px !important;
    }
    .landingpage_Contactpage .Conatactform-Container .form-Column .form-group {
        margin-bottom: 8px !important;
    }
    .landingpage_Contactpage .Conatactform-Container .form-Column  .label-name{

        text-transform: uppercase;
        font-family: 'Lato', sans-serif;
        color: #2D2D2D;
        letter-spacing: 1px;
        font-size: 14px !important;

    }
    /*.landingpage_Contactpage .Conatactform-Container{*/
    /*    padding: 0px !important;*/
    /*}*/
    /*.landingColumn1{*/
    /*    order:2*/
    /*}*/
    /*.landingColumn2{*/
    /*    order: 1;*/
    /*    min-height: 40vh !important*/
    /*}*/
    /*.landingpage_Contactpage .Conatactform-Container .contact-header{*/
    /*    display: block !important;*/
    /*    text-align: center;*/

    /*}*/
}
@media screen and (max-width:700px) {
    .landingpage_Contactpage{ background-image: url("../../Assets/Images/landingpage2020bg.png");
        background-size: 100% 100% !important;
        background-position: center;
        height: 100vh;
        background-repeat: no-repeat;
    }
    .landingpage_Contactpage .Conatactform-Container .form-Column{
        width: 100vw !important;
        margin-bottom: 0px !important;
    }
    .landingpage_Contactpage .Conatactform-Container .contact-header .contact_Logo{
        width: 27% !important;
        padding: 10px;

    }
    .landingpage_Contactpage .Conatactform-Container .form-Column {
        margin-top: 14px !important;
        border-radius:0px !important;
    }
    .landingpage_Contactpage .Conatactform-Container .form-Column .Forecastformtitle {
        font-size: 18px !important;
    }
    .landingpage_Contactpage .Conatactform-Container .form-Column .form-group {
        margin-bottom: 5px !important;
    }
    .landingpage_Contactpage .Conatactform-Container .form-Column  .label-name{

        text-transform: uppercase;
        font-family: 'Lato', sans-serif;
        color: #2D2D2D;
        letter-spacing: 1px;
        font-size: 14px !important;

    }
    .landingpage_Contactpage .Conatactform-Container{
        padding: 0px !important;
    }
    .landingColumn1{
        order:2
    }
    .landingColumn2{
        order: 1;
        min-height: 30vh !important;
    }
    .landingpage_Contactpage .Conatactform-Container .contact-header{
        display: block !important;
        text-align: center;

    }
    .landingForm{
        padding-bottom: 60px;
    }
    .landingpage_Contactpage .bg_2020_forecast{
        width: 80% !important;
        margin: 0 auto !important;
    }
    .landingpage_Contactpage .Conatactform-Container .form-Column form .forecastContactformSubmitBtn{
        position: fixed !important;
        bottom: 0px;
    }
}
.landingpage_Contactpage .form-Column .btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #FA2129;
    border-color: #FA2129;
}

.landingpage_Contactpage .bg_2020_forecast{
    background-image: url("../../Assets/Images/forecast_2020.png");
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    margin:0 auto;

}

