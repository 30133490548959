.team_color_vision_component .teams {
    color: #954291;
    font-family: 'Neo-Sans', sans-serif;
}

.team_color_vision_component {
    background-color: #FFFBF6;
}
.team_color_vision_component .spiral-header{
    background:#0E4A9B ;
    color: white;
}
.team_color_vision_title{
    font-weight: bold;
    font-family: 'Neo-Sans', sans-serif;
}
.team_color_vision_component p{
    font-family: 'lato', sans-serif;
}






