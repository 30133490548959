.fade .modal .show {
  z-index: 1500 !important;
  background-color: #0000008c !important;
  padding-left: 0px !important;
}
.modal-backdrop {
  background-color: #707070 !important;
}
.modal-backdrop.show {
  opacity: 1 !important;
}
.indiaBloomModal {
  width: 80vw !important;
  max-width: 80vw !important;
}
.indiaBloomBg {
  background: url("./../../Assets/Images/INDIA BLOOM MAIN.jpg") no-repeat center;
  min-height: 50vh;
  background-size: cover;
}

.indiaBloomModalTitle {
  font-size: 50px;
  font-family: "Neo-Sans", sans-serif !important;
  font-weight: 600 !important;
}
.indiaBloomModalArticle p {
  font-size: 20px;
  font-family: "Lato", sans-serif;
  font-weight: normal;
  line-height: 1.618em;
  color: #343434;
}
.CloseBtn {
  position: absolute;
  z-index: 2000;
  /* float: right; */
  right: -63px;
  top: 25px;
  border-radius: 30px;
  width: 50px;
  height: 50px;
  background-color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
}
.list-region_title {
  font-family: "Neo-Sans", sans-serif !important;
  font-weight: 600 !important;
  font-size: 25px !important;
  color: rgba(255, 255, 255, 1) !important;
}
.ThemeExploreBg1 {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("./../../Assets/Images/new-Youth.png") no-repeat center;
  background-size: cover;
  min-height: 50vh;
  cursor: pointer;
}
.ThemeExploreBg2 {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("./../../Assets/Images/new-North.png") no-repeat center;
  background-size: cover;
  min-height: 50vh;
  cursor: pointer;
}
.ThemeExploreBg3 {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("./../../Assets/Images/new-South.png") no-repeat center;
  background-size: cover;
  min-height: 50vh;
  cursor: pointer;
}
.ThemeExploreTitle {
  font-size: 30px !important;
  font-family: "Neo-Sans", sans-serif !important;
  color: #000000 !important;
  font-weight: bolder !important;
  text-align: center !important;
}
.indiaBloomSubTitle {
  font-size: 20px !important;
  font-family: "Lato", sans-serif !important;
  color: #fff !important;
}
@media screen and (max-device-width: 1000px) {
  .CloseBtn {
    position: absolute;
    z-index: 2000;
    /* float: right; */
    right: 3px;
    top: 5px;
  }
  .indiaBloomModal {
    width: 100vw !important;
    max-width: 100vw !important;
    margin: 0 !important;
  }
  .indiaBloomModalTitle {
    font-size: 30px;
  }
  .ThemeExploreTitle {
    font-size: 20px !important;
    font-family: "Neo-Sans", sans-serif !important;
  }
}
