.ThemeListPage .theme_bg {
  background: url("./../../Assets/Images/South_theme_bg.png") no-repeat top;
  background-size: 100% 50vh !important;
}
.ThemeListPage .ThemeListTitle {
  font-size: 30px;
  font-family: "Lato", sans-serif;
  color: #ffffff;
  font-weight: bolder;
  text-transform: uppercase;
  background-color: #0000006b;
}
.ThemeListPage .ThemeExploreTitle {
  font-size: 30px;
  font-family: "Neo-Sans", sans-serif;
  color: #ffffff;
  font-weight: bolder;
}
.ThemeListPage .card {
  width: 18rem;
  border-radius: 10px !important;
  border: none !important;
}

.ThemeListPage .custom-card-img{
  height: 75px!important;
  width: 100%!important;
  object-fit: contain;
}


.ThemeListPage .card-body {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  align-items: center!important;
}
.ThemeListPage .CardBtn {
  text-decoration: underline !important;
  font-weight: 600 !important;
  background-color: transparent !important;
  border: none !important;
}
.ThemeListPage .CardBtn:focus {
  box-shadow: none !important;
}
@media screen and (min-device-width: 768px) and (max-device-width: 1300px) {
  .ThemeListPage .card {
    width: 16rem;
  }
}
.ThemeListPage .ThemeExploreBg1 {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("./../../Assets/Images/new-Youth.png") no-repeat center;
  background-size: cover;
  min-height: 50vh;
  cursor: pointer !important;
}
.ThemeListPage .ThemeExploreBg2 {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("./../../Assets/Images/new-North.png") no-repeat center;
  background-size: cover;
  min-height: 50vh;
  cursor: pointer !important;
}
.ThemeListPage .ThemeExploreBg3 {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("./../../Assets/Images/new-South.png") no-repeat center;
  background-size: cover;
  min-height: 50vh;
  cursor: pointer !important;
}
.ThemeListPage .list-region_title {
  font-family: "Neo-Sans", sans-serif !important;
  font-weight: 600;
  font-size: 25px;
  color: rgba(255, 255, 255, 1);
}
.ThemeListPage .indiaBloomSubTitle {
  font-size: 18px;
  font-family: "Lato", sans-serif;
  color: #fff;
}
.empty_msg {
  text-align: center;
  padding: 10rem;
  font-size: 1.5rem;
  color: #ffffff;
  text-transform: uppercase;
  width: 100%;
}

.theme_list_card_body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
