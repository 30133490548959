html,
body {
  overflow-x: hidden;
}
.HomePageBg {
  position: relative;
  display: block;
  /*width: 100vw;*/
  /*height: 100vh;*/
  padding-top: 0;
}
.Bg {
  position: relative;
  display: block;
  width: 100vw;
  height: 100vh;
  padding-top: 0;
}
#myVideo1 {
  position: absolute;
  /*right: 0;*/
  /*bottom: 0;*/
  min-width: 100%;
  min-height: 100%;
}
#myVideo {
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}
.experienceBtn {
  border-radius: 40px !important;
  border: 1px solid #c2af52 !important;
  background-color: #fff5c4 !important;
  color: #3c336d !important;
  font-family: "Lato", sans-serif !important;
  font-weight: bold !important;
  font-size: 12px !important;
  letter-spacing: 0.96px !important;
  box-shadow: 0 3px 6px #00000029;
}

.experienceBtn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(206, 189, 106, 0.71) !important;
}
.videoContent {
  z-index: 10;
}
#section02 {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("./../../Assets/Images/India-Bloom-header.jpg") no-repeat;
  min-height: 75vh;
  background-size: cover;
}
.indiaBloomTitle {
  font-size: 40px;
  font-family: "Neo-Sans", sans-serif;
  color: #fff;
}

.indiaBloomSubTitle {
  font-size: 20px !important;
  font-family: "Lato", sans-serif;
  color: #fff;
}
.YouthRegionBg {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("./../../Assets/Images/new-Youth.png") no-repeat center;
  background-size: cover;
  min-height: 100vh;
}
.NorthRegionBg {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("./../../Assets/Images/new-North.png") no-repeat center;
  background-size: cover;
  min-height: 50vh;
}
.SothRegionBg {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("./../../Assets/Images/new-South.png") no-repeat center;
  background-size: cover;
  min-height: 50vh;
}
.overlayImg {
  background-color: #00000040;
  width: 100%;
  height: 100%;
  position: absolute;
  /*top: 0;*/
}
.Contact_bg {
  background: url("./../../Assets/Images/color-evolve.jpg") no-repeat top center;
  background-size: cover;
  min-height: 50vh;
  /*clip-path: circle(100.0% at 0 50%);*/
}

@media screen and (min-width: 1000px) {
  .videoContent {
    width: 75vw;
  }
}
.ContactFormTitle {
  font-size: 30px;
  font-family: "Lato", sans-serif;
  color: #000000;
  font-weight: 700;
  opacity: 0.8;
}
.submitBtn {
  background-color: #304cb9 !important;
  border-color: #304cb9 !important;
  box-shadow: 0 3px 6px #00000029;
}
.ContactField {
  border-radius: 18px !important;
  background-color: #ffffff !important;
  font-family: "Lato", sans-serif !important;
}

.color_process_video_secion {
  position: relative;
  display: block;
  width: 100%;
}
.journey_content {
  font-family: "lato", sans-serif;
}
.buttonDiv {
  position: fixed;
  z-index: 1000;
  top: 0;
  /*display: flex;*/
  right: 0px;
  padding: 20px 150px;
  flex-direction: column;
  /*width: 30%;*/
  /*display: none;*/
}

@media screen and (max-width: 700px) {
  .HomePageBg {
    /* max-height: 232.88px; */
    height: 85vh;
  }
  .embed-responsive-video {
    height: auto !important;
  }
  .buttonDiv {
    display: block;
    margin: 0 auto;
    position: absolute;
    height: 0;
    left: 0px;
  }
}
.buttonDiv .forecastformBtn {
  left: 0px;
  font-size: 18px !important;
  font-weight: bold;
}
.buy-now {
  display: flex;
  margin-top: 60px;
  /* width: 2074px; */
  height: 347px;
  /* padding: 108px 566px 107px 567px; */
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: url("./../../Assets/Images/Buy-now-Footer.png") no-repeat top
    center;
  backdrop-filter: blur(25px);
  position: relative;
}
.buy-now-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
}
.buy-now-cta {
  border-radius: 40px !important;
  border: 1px solid #c2af52 !important;
  background: #fff5c4 !important;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16) !important;
  padding: 15px 19px 15px 20px !important;
  color: #3c336d !important;
  text-align: center !important;
  font-family: Lato !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  letter-spacing: 0.96px !important;
}
.physical-title-section {
  color: #fff;
  text-align: center;
  font-family: Lato;
  font-size: 72px;
  font-style: normal;
  font-weight: 600;
  line-height: 70px;
}
@media screen and (max-width: 1024px) {
  .physical-title-section {
    color: #fff;
    text-align: center;
    font-family: Lato;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 50px;
  }
  .book-img {
    /* display: none; */
    bottom: -25% !important;
  }
  .book-img img {
    max-width: 168px !important;
  }
}
