.HeaderNav {
    position: fixed;
    z-index: 1200;
    top: 0;
    display: flex;
    width: 10%;
    height: 70px;
}
.hamburger{
    background: #616161B0 0% 0% no-repeat padding-box;
}
.hamburger .line{
    width: 35px;
    height: 5px;
    background-color: #ecf0f1;
    display: block;
    margin: 8px 0;
    transition: all 0.3s ease-in-out;
    border-radius: 10px;
}

.hamburger:hover{
    cursor: pointer;
}
#hamburger-1.is-active .line:nth-child(2){
    opacity: 0;
}

#hamburger-1.is-active .line:nth-child(1){
    -webkit-transform: translateY(13px) rotate(45deg);
    -ms-transform: translateY(13px) rotate(45deg);
    -o-transform: translateY(13px) rotate(45deg);
    transform: translateY(13px) rotate(45deg);
}

#hamburger-1.is-active .line:nth-child(3){
    -webkit-transform: translateY(-13px) rotate(-45deg);
    -ms-transform: translateY(-13px) rotate(-45deg);
    -o-transform: translateY(-13px) rotate(-45deg);
    transform: translateY(-13px) rotate(-45deg);
}
.Logo{
    background: #61616166 0% 0% no-repeat padding-box;

}
.HeaderModal{
    width: 100vw !important;
    max-width: 100vw !important;
    margin: 0 !important;
}
.HeaderModal .modal-content{
    border: none;
    border-radius: 0px;
    height: 100vh;

}
.HeaderModal .modal-body{
    background-color: #16214D;
    color: #ffffff;
    /*background-image: url("./../../Assets/Images/Modal-bg.svg");*/
    background-position: bottom;
    background-repeat-y: no-repeat;
    background-position-y: bottom;
}
body.modal-open {
    height: 100vh;
    overflow-y: hidden;
}
.ModalTitle{
    font-size: 30px;
    font-family: 'Neo-Sans', sans-serif;
    color: #fff;
    cursor: pointer;
}
.fade .modal .show{
    height: 100vh !important;
    overflow-y: hidden;
}
.ModalTitle:after {

    background: none repeat scroll 0 0 transparent;
    /*bottom: 0;*/
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    /*position: absolute;*/
    background: #fff;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}
.ModalTitle:hover:after {
    width: 100%;
    left: 0;
}

/*.ModalTitle {text-decoration: none;}*/
/*.ModalTitle:hover {text-decoration: underline;}*/
