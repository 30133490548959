.mini-footer {
  background: #000000 no-repeat padding-box;
  opacity: 1;
  backdrop-filter: blur(50px);
  text-align: center;
  padding: 15px 0;
  bottom: 0px;
  width: 100vw;
}
.mini-footer p {
  margin: 0;
  line-height: 26px;
  font-size: 15px;
  color: #ffffff;
  font-family: "Lato", sans-serif;
}
.mini-footer p a {
  color: #5867dd;
}
.mini-footer p a:hover {
  color: #34bfa3;
}
.widget-about img {
  display: block;
  margin-bottom: 30px;
}
.widget-about p {
  font-weight: 400;
}
.widget-about .contact-details {
  margin: 30px 0 0 0;
}
.widget-about .contact-details li {
  margin-bottom: 10px;
}
.widget-about .contact-details li:last-child {
  margin-bottom: 0;
}
.widget-about .contact-details li span {
  padding-right: 12px;
}
.widget-about .contact-details li a {
  color: #5867dd;
}
@media (max-width: 991px) {
  .footer-menu {
    padding-left: 0;
  }
}
@media screen and (max-width: 900px) {
  .nipponLogo {
    width: 25% !important;
  }
  .colorvision-footer {
    bottom: 95px;
    position: relative;
  }
}
