.forecast_contactform {
  background-image: url("../../Assets/Images/forecastBg1.jpg");
  background-size: cover;
  background-position: center;
  height: auto !important;
  background-repeat: no-repeat;
}
.forecast_contactform .Conatactform-Container {
  padding: 0px 45px;
}
.forecast_contactform .Conatactform-Container .contact-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.forecast_contactform .Conatactform-Container .contact-header .contact_Logo {
  padding: 20px 0px 0px 0px;
  width: 8%;
  cursor: pointer;
}
.forecast_contactform .Conatactform-Container .contact-header .header_text {
  color: #ffffff;
  opacity: 1;
  font-family: Neo-Sans, serif;
  font-weight: 300;
  font-size: 20px;
}
.forecast_contactform .Conatactform-Container .form-Column {
  width: 28vw;
  background-color: #fff;
  border-radius: 4px;
  padding: 10px 0px 0px 0px;
  margin-top: 50px;
}
.forecast_contactform
  .Conatactform-Container
  .form-Column
  form
  .forecastContactformSubmitBtn {
  margin: 0px !important;
  border-radius: 0px 0px 4px 4px !important;
  text-transform: uppercase;
  background-color: #fa2129;
  padding: 10px;
  border: 2px solid #fa2129b8;
}
.forecast_contactform
  .Conatactform-Container
  .form-Column
  form
  .forecastContactformSubmitbuynowBtn {
  margin: 0px !important;
  border-radius: 0px 0px 4px 4px !important;
  text-transform: uppercase;
  background-color: #fa2129;
  padding: 10px;
  border: 2px solid #fa2129b8;
}
.forecast_contactform .Conatactform-Container .form-Column form button:focus {
  outline: none;
  box-shadow: none;
}
.forecast_contactform .Conatactform-Container .form-Column .form-group {
  padding: 0px 20px;
}
.forecast_contactform .Conatactform-Container .form-Column .Forecastformtitle {
  padding: 0px 20px;
  font-family: Neo-Sans, sans-serif;
  font-weight: bold;
  font-size: 25px;
}
.forecast_contactform
  .Conatactform-Container
  .form-Column
  ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #000000 !important;
  opacity: 0.35 !important;
  font-weight: bold !important;
  font-size: 14px !important;
}
.forecast_contactform .Conatactform-Container .form-Column ::placeholder {
  /* Microsoft Edge */
  color: #000000 !important;
  opacity: 0.35 !important;
  font-weight: bold !important;
  font-size: 14px !important;
}
.forecast_contactform
  .Conatactform-Container
  .form-Column
  :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000000 !important;
  opacity: 0.35 !important;
  font-weight: bold !important;
  font-size: 14px !important;
}
.forecast_contactform .Conatactform-Container .form-Column .form-control:focus {
  outline: none;
  box-shadow: none;
}
.forecast_contactform .Conatactform-Container .form-Column .label-name {
  text-transform: uppercase;
  font-family: "Lato", sans-serif;
  color: #2d2d2d;
  letter-spacing: 1px;
}

.forecast_contactform .Conatactform-Container .colorCode_div {
  width: 230px;
  height: 260px;
  background-color: #ffffff;
  border-radius: 20px;
  padding: 20px;
}
.forecast_contactform .Conatactform-Container .colorCode_div .insideDiv {
  background-color: #0d499c;
  height: 151px;
  border-radius: 10px;
}
.forecast_contactform .Conatactform-Container .colorCode_div .color_Name {
  font-weight: bold;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0px;
  margin-top: 10px;
}
.forecast_contactform .Conatactform-Container .colorCode_div .color_Code {
  font-size: 16px;
  font-family: "Lato", sans-serif;
  font-weight: 400;
}
.forecast_contactform .Conatactform-Container .bgIndex {
  position: absolute;
  bottom: 0px;
  float: right;
  right: 0px;
  display: flex;
  align-items: center;
  width: 40%;
  justify-content: space-around;
}
.forecast_contactform .Conatactform-Container .bgIndex .indexNum {
  font-family: "Lato", sans-serif;
  font-size: 50px;
  color: #fff;
}
.forecast_contactform .Conatactform-Container .bgIndex .totalindex {
  font-family: "Lato", sans-serif;
  font-size: 30px;
  color: #fff;
  font-weight: 300;
}

@media screen and (max-width: 1500px) {
  .forecast_contactform .Conatactform-Container .form-Column {
    margin-top: 14px !important;
  }
  .forecast_contactform
    .Conatactform-Container
    .form-Column
    .Forecastformtitle {
    font-size: 18px !important;
  }
  .forecast_contactform .Conatactform-Container .form-Column .form-group {
    margin-bottom: 5px !important;
  }
  .forecast_contactform .Conatactform-Container .form-Column .label-name {
    text-transform: uppercase;
    font-family: "Lato", sans-serif;
    color: #2d2d2d;
    letter-spacing: 1px;
    font-size: 14px !important;
  }
}
.forecast_contactform
  .form-Column
  .btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #fa2129;
  border-color: #fa2129;
}
.main-buy-now-section{
    display: flex;
    /* gap: 20px; */
    margin-top: 20px;
}