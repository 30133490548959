.forecastform{
    position: fixed;
    z-index: 1000;
    z-index: 1000;
    top: 0;
    /*display: flex;*/
    right: 0px;
    padding: 20px 150px;
    flex-direction: column;
    /*width: 30%;*/
    /*display: none;*/


}
.forecastformBtn{
    background-color: #5A3AFF !important;
    border-radius: 20px !important;
    border: none !important;
    padding: 10px 20px !important;
    box-shadow: 0px 5px 10px #00000029;

}
.FormStyle{
    background-color: #5a3aff;
    padding: 18px;
    /*margin: 17px;*/
}
.formTitle{
    font-size: 20px;
    font-family: 'Neo-Sans', sans-serif !important;
    color: #ffffff;
}

@media screen and (max-width: 700px){
    .forecastform {
        position: absolute;
        z-index: 1000;
        top: 100px;
        /*display: flex;*/
        padding:0px ;
        width: 100vw;

    }
    .buttonDiv{
        display: block;
        margin: 0 auto;
        position: absolute;
        height: 0;
        left: 0px;
    }
    .bs-popover-bottom {
        margin-top: .5rem;
        max-width: 90% !important;
    }
    .forecastformBtn {
        font-size: 12px !important;
        position: fixed;
        width: 100vw;
        border-radius: 0px !important;
        bottom: 0px;
    }

}
.forecastformSubmitBtn{
    background-color: #927ff5 !important;
    border: none !important;
}
.forecastformSubmitBtn:active:focus ,.forecastformSubmitBtn:focus{
    box-shadow: 0 0 0 0.2rem rgb(114, 91, 234) !important;
}
.popover-body .form-label{
    color: #ffffff !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    font-family: 'Neo-Sans', sans-serif !important;
}
.popover-body ::placeholder { /* Microsoft Edge */
    color: #000000 !important;
    opacity: 0.16 !important;
    font-weight: bold !important;
}

.popover-body :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #000000 !important;
    opacity: 0.16 !important;
    font-weight: bold !important;

}

.popover-body ::-ms-input-placeholder { /* Microsoft Edge */
    color: #000000 !important;
    opacity: 0.16 !important;
    font-weight: bold !important;

}
.bs-popover-bottom {
    margin-top: 1rem !important;
    max-width: 90% !important;
}
.popover-body {
     padding: 0 !important;
    color: #ffffff !important;
}
.bs-popover-bottom>.arrow::after {
    top: -7px !important;
    border-width: 0 1rem 1rem 1rem !important;
    border-bottom-color: #5a3aff !important;
}
.bs-popover-bottom>.arrow::before {
    top: -7px !important;
    border-width: 0 1rem 1rem 1rem !important;

}

