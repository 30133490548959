.TeamPage{
    background-color: #FFFBF6;
    height: 100vh;
}

.TeamTitle{
    padding-top: 75px;
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    font-family: 'Neo-Sans', sans-serif;
}
.TeamName{
    text-align: left;
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    font-weight: bold;
}
.TeamDesc {
    text-align: left;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: normal;
    color: #171717;
    opacity: 0.7;
}
.userImage{
    /*min-height: 30vh;*/
    box-shadow: 0px 10px 20px #00000029;
}
@media screen and (max-width: 700px) {
    .userImage{
        min-height: 50vh !important;

    }
}

.teams {
    color: #954291;
}
